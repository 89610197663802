<template>
  <page-layout class="ma-24">
    <!-- 企业油卡账户 -->
    <!-- 列表 -->
    <div class="bg-white">
      <p-table
        rowKey="id"
        :dataSource="dataSource"
        class="bg-white"
        dataKey="modal"
      >
        <p-t-column type="seq" width="60" title="序号" />
        <p-t-column field="sp_name" min-width="120" title="服务商" />
        <p-t-column field="card_type_desc" min-width="120" title="账户类型" />
        <p-t-column
          field="recharge_amount"
          min-width="120"
          title="充值金额（元）"
          v-slot="{ row }"
          >{{ (row.recharge_amount / 100).toFixed(2) }}</p-t-column
        >
        <p-t-column
          field="payment_amount"
          min-width="120"
          title="消费金额（元）"
          v-slot="{ row }"
        >
          {{ (row.payment_amount / 100).toFixed(2) }}
        </p-t-column>
        <p-t-column
          field="balance"
          min-width="120"
          title="账户余额（元）"
          v-slot="{ row }"
          >{{ (row.balance / 100).toFixed(2) }}</p-t-column
        >
        <p-t-column field="order_count" min-width="120" title="订单数" />
        <p-t-column field="status_desc" min-width="120" title="状态" />
        <p-t-column min-width="200" title="操作" fixed="right">
          <template v-slot="{ row }">
            <a-button type="link" style="padding:0;" @click="rechargePage(row)"
              >充值记录</a-button
            >
            <a-button
              v-if="$store.getters.roles.includes('ConsumerOrders')"
              type="link"
              @click="handleViewOrder(row)"
              >查看订单</a-button
            >
          </template>
        </p-t-column>
      </p-table>
    </div>
  </page-layout>
</template>
<script>
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";
import { getInitTable } from "@/hooks/form-hook";
import { Table } from "@wlhy-web-components/common";
import { useGasCardDetailApi } from "@/apis/oil";

export default defineComponent({
  name: "account-enterprise",
  setup() {
    const router = useRouter();
    const tableDataSource = reactive(getInitTable());

    const gasCardDetail = useGasCardDetailApi();

    const getConsultingList = Table.useTableConfig({
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const res = await gasCardDetail(data);
        return { records: res.list, total: res.total };
      }
    });

    // 跳转到开票记录详情
    const handleActionClick = row => {
      console.log(row);
      let details = {
        id: row.id,
        name: row.apply_user,
        time: row.apply_time,
        total: row.amount
      };
      router.push({
        path: "/invoice/invoice-detail",
        query: details
      });
    };
    //查看订单跳转
    const handleViewOrder = row => {
      let details = {
        company_id: row.company_id,
        sp_code: row.sp_code
      };
      router.push({
        path: "/oilCard/consumer-orders",
        query: details
      });
    };
    //充值记录跳转
    const rechargePage = row => {
      let details = {
        card_no: row.card_no,
        sp_code: row.sp_code
      };
      router.push({
        path: "/oilCard/account-enterprise-recharge",
        query: details
      });
    };

    return {
      rechargePage,
      getConsultingList,
      handleViewOrder,
      handleActionClick,
      dataSource: tableDataSource
    };
  }
});
</script>
